let isScrolling = false;
let lastPortrait = 0;
let lastLandscape = 0;

const update = value => {
    document.documentElement.style.setProperty('--vh', `${value * 0.01}px`);
};

const setLastValues = () => {
    if (window.innerWidth > window.innerHeight) {
        lastLandscape = window.innerHeight;
    } else {
        lastPortrait = window.innerHeight;
    }
};

const getLastValues = () => {
    if (window.innerWidth > window.innerHeight) {
        return lastLandscape || window.innerHeight;
    }
    return lastPortrait || window.innerHeight;
};

const getScrollTop = () => {
    return (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
}

const orientationchange = () => {
    setTimeout(() => {
        const scrollTop = getScrollTop();
        window.scrollTo(0, -100);
        setTimeout(() => {
            update(getLastValues());
            window.scrollTo(0, scrollTop);
        });
    }, 60);
};

const resize = () => {
    if (!isScrolling) {
        update(window.innerHeight);
        setLastValues();
    }
};

const scrollTouchOrMove = e => {
    window.clearTimeout(isScrolling);
    isScrolling = setTimeout(() => {
        isScrolling = 0;
    }, 120);
};

const init = () => {
    window.addEventListener('scroll', scrollTouchOrMove, { capture: false, passive: true });
    window.addEventListener('touchstart', scrollTouchOrMove, { capture: false, passive: true });
    window.addEventListener('touchmove', scrollTouchOrMove, { capture: false, passive: true });
    window.addEventListener('touchend', scrollTouchOrMove, { capture: false, passive: true });

    window.addEventListener('resize', () => {
        requestAnimationFrame(resize);
    }, { capture: false, passive: true });

    window.addEventListener('orientationchange', () => {
        requestAnimationFrame(orientationchange);
    }, { capture: false, passive: true });

    resize();
};

export default {
    init
};
