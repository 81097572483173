import $ from '@vaersaagod/tools/Dom';
import agent from '@vaersaagod/tools/request';
import serialize from 'form-serialize';
import gsap from 'gsap';

export default el => {
    const form = $(el);
    const button = form.find('[data-submit]');
    const receipt = form.find('[data-receipt]');

    const onError = response => {
        button.attr('disabled', null);
        Object.keys(response.errors).forEach(key => {
            const input = form.find(`[name="${key}"]`);
            input.addClass('invalid');
            input.parent().find('p').remove();
            input.parent().append(`<p class="mt-15" data><span class="c-red">*</span> ${response.errors[key]}</p>`);
        });
    };

    const onSuccess = response => {
        const btn = button.get(0);
        const rct = receipt.get(0);

        gsap.to(btn, { duration: 0.4, opacity: 0, y: 50, ease: 'circ.in' });

        receipt.attr('hidden', null);
        gsap.set(rct, { y: -30, opacity: 0 });
        gsap.to(rct, { delay: 0.4, duration: 0.4, y: 0, opacity:1, ease: 'circ.out' });

        el.reset();
    };

    const submitForm = () => {
        button.attr('disabled', '');
        agent
            .post('/')
            .accept('application/json')
            .send(serialize(el))
            .end((error, res) => {
                const response = JSON.parse(res.text);
                if (error || !response.success) {
                    onError(response);
                } else {
                    onSuccess(response);
                }
            });
    };

    const checkIfValid = () => {
        if ('checkValidity' in el) {
            if (el.checkValidity()) {
                button.addClass('valid');
            } else {
                button.removeClass('valid');
            }
        }
    };

    const init = () => {
        form.attr('novalidate', '');
        form.on('submit', e => {
            e.preventDefault();
            submitForm();
        });
        form.find('[required]').on('input', e => {
            $(e.target).removeClass('invalid').parent().find('p')
                .remove();
            checkIfValid();
        });
    };

    const destroy = () => {};

    return {
        init,
        destroy
    };
};
