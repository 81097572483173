import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';

export default class AutoplayVideo {
    constructor(el) {
        this.$el = $(el);

        this.$video = this.$el.is('video') ? this.$el : this.$el.find('video');
        this.video = this.$video.get(0);

        if (!this.video) {
            this.cantPlayVideo();
            return;
        }

        this.intersecting = false;
        this.videoHasLoaded = false;
        this.videoIsPlaying = false;
        this.videoFallbackTimer = null;
        this.hideImageTimer = null;

        this.sources = {
            landscape: this.$video.data('src'),
            portrait: this.$video.data('src')
        };

        if (!this.sources.landscape && !this.sources.portrait) {
            this.cantPlayVideo();
            return;
        }

        if (!this.video.canPlayType || !this.video.canPlayType('video/mp4')) {
            this.cantPlayVideo();
            return;
        }

        this.onLoadStart = this.onLoadStart.bind(this);
        this.onTimeUpdate = this.onTimeUpdate.bind(this);
        this.onFallbackTimer = this.onFallbackTimer.bind(this);
        this.onResize = this.onResize.bind(this);
        this.onIntersectionChange = this.onIntersectionChange.bind(this);

        this.observer = new IntersectionObserver(this.onIntersectionChange, {
            threshold: 0,
            root: null
        });

        this.observer.observe(this.video);

        this.init();
    }

    onIntersectionChange(entries) {
        if (entries.filter(entry => entry.isIntersecting).length) {
            // console.log('intersecting play');
            this.intersecting = true;
            if (!this.maybeSwapSource()) {
                this.playAndCatch();
            }
        } else {
            // console.log('not intersecting pause');
            this.videoIsPlaying = false;
            this.intersecting = false;
            this.video.pause();
        }
    }

    getVideoFormat() {
        return this.$el.height() / this.$el.width() > 1 ? 'portrait' : 'landscape';
    }

    init() {
        Viewport.on('resize', this.onResize);
    }

    destroy() {
        if (this.videoIsPlaying) {
            this.$el.find('[data-image]').css({
                visibility: ''
            });
        }
        if (this.hideImageTimer) {
            clearTimeout(this.hideImageTimer);
            this.hideImageTimer = null;
        }
        this.killVideo();
        this.$el
            .css({
                visiblity: ''
            })
            .removeClass('js-is-playing js-cant-play-video');
    }

    stopFallbackTimer() {
        if (!this.video || !this.videoFallbackTimer) {
            return;
        }
        clearTimeout(this.videoFallbackTimer);
        this.videoFallbackTimer = null;
    }

    startFallbackTimer(interval) {
        this.stopFallbackTimer();
        this.videoFallbackTimer = setTimeout(this.onFallbackTimer, interval);
    }

    addVideoEventListeners() {
        if (!this.video) {
            return;
        }
        this.video.addEventListener('timeupdate', this.onTimeUpdate);
        this.video.addEventListener('loadstart', this.onLoadStart);
        this.video.addEventListener('loadedmetadata', this.onLoadStart);
        this.video.addEventListener('loadeddata', this.onLoadStart);
        this.video.addEventListener('canplay', this.onLoadStart);
    }

    removeVideoEventListeners() {
        if (!this.video) {
            return;
        }
        this.video.removeEventListener('timeupdate', this.onTimeUpdate);
        this.video.removeEventListener('loadstart', this.onLoadStart);
        this.video.removeEventListener('loadedmetadata', this.onLoadStart);
        this.video.removeEventListener('loadeddata', this.onLoadStart);
        this.video.removeEventListener('canplay', this.onLoadStart);
    }

    killVideo() {
        this.stopFallbackTimer();
        this.removeVideoEventListeners();
        if (!this.video) {
            return;
        }
        this.video.pause();
        this.videoIsPlaying = false;
        this.videoHasLoaded = false;
    }

    maybeSwapSource() {
        if (!this.video || !this.intersecting) {
            return false;
        }

        const currentFormat = this.getVideoFormat();
        const currentSource = this.video.getAttribute('src');
        const wantedSource = this.sources[currentFormat];

        if (!wantedSource || currentSource === wantedSource) {
            return false;
        }

        this.killVideo();
        this.video.src = wantedSource;

        this.playAndCatch();
        return true;
    }

    playAndCatch() {
        if (!this.video || !this.intersecting) {
            return;
        }

        if (this.videoHasLoaded && !this.videoIsPlaying) {
            this.video.play();
            return;
        }

        setTimeout(() => {
            this.addVideoEventListeners();
            this.video.muted = true;
            this.startFallbackTimer(1000);

            const promise = this.video.play();

            if (promise !== undefined) {
                promise.then(() => {
                    // console.log('playAndCatch.play');
                    this.stopFallbackTimer();
                }).catch(e => {
                    // console.log(['playAndCatch.catch', e]);
                    if (e.name === 'NotAllowedError' || e.name === 'NotSupportedError') {
                        // console.log('playAndCatch.catch: killing it');
                        this.cantPlayVideo();
                    }
                });
            }
        }, parseFloat(this.$el.data('delay')) * 1000);
    }

    cantPlayVideo() {
        this.killVideo();
        this.$el.addClass('js-cant-play-video');
        this.$video.css({
            visiblity: 'hidden'
        });
        const $image = this.$el.find('[data-image] img');
        if ($image.length && !$image.hasClass('lazyload')) {
            $image.addClass('lazyload');
        }
    }

    onFallbackTimer() {
        this.cantPlayVideo();
    }

    onResize() {
        this.maybeSwapSource();
    }

    onVideoPlaying() {
        this.videoHasLoaded = true;
        this.videoIsPlaying = true;
        this.hideImageTimer = setTimeout(() => {
            this.$el.find('[data-image]').css({
                visibility: 'hidden'
            });
        }, 2000);
        requestAnimationFrame(() => {
            this.$el.addClass('js-is-playing');
        });
    }

    onTimeUpdate() {
        if (!this.video || this.video.currentTime < 0.001) {
            return;
        }
        this.removeVideoEventListeners();
        this.stopFallbackTimer();
        this.onVideoPlaying();
    }

    onLoadStart() {
        if (this.videoIsPlaying) {
            return;
        }
        this.startFallbackTimer(6000);
    }
}
