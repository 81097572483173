import $ from '@vaersaagod/tools/Dom';
import gsap from 'gsap';

export default el => {
    const accept = $(el).find('[data-accept]');

    const init = () => {
        accept.on('click', e => {
            window.vrsg.setCookie('cookies-disclaimed', 1, 365);
            gsap.to(el, { duration: 1, y: '100%', ease: 'expo.inOut', onComplete: () => {
                el.remove();
            }});
        });
    };

    const destroy = () => {
        accept.off('click');
    };

    return {
        init,
        destroy
    };
};
