import gsap from 'gsap';
import $ from '@vaersaagod/tools/Dom';
import Dispatch from '@vaersaagod/tools/Dispatch';
import Viewport from '@vaersaagod/tools/Viewport';
import * as Events from '../lib/events';

export default el => {
    const dom = $(el);
    const items = dom.find('[data-item]').nodes;
    const toggle = $('[data-component="MenuToggle"]').get(0);
    const shader = $('[data-menu-shader]').get(0);

    let active = false;

    const keyHandler = e => {
        const key = e.keyCode || e.which;
        if (active && key === 27) {
            Dispatch.emit(Events.MENU_CLOSE);
        }
    };

    const killAllTweens = () => {
        gsap.killTweensOf([el, shader, items]);
    };

    const setInitialItemState = () => {
        killAllTweens();
        dom.attr('hidden', '');
        gsap.set(el, { x: '100%' });
        gsap.set(items, { opacity: 0, x: 40 });
    };

    const open = () => {
        if (!active) {
            active = true;
            killAllTweens();
            dom.attr('hidden', null);
            document.addEventListener('keyup', keyHandler);
            gsap.to(el, { duration: 0.4, x: '0%', ease: 'power2.inOut' });
            gsap.to(shader, { duration: 0.55, autoAlpha: 0.85, ease: 'power2.inOut' });
            gsap.to(items, { delay: 0.25, duration: 1.25, x: 0, ease: 'expo.out', stagger: 0.0375 });
            gsap.to(items, { delay: 0.25, duration: 0.3, opacity: 1, ease: 'sine.out', stagger: 0.0375 });
            Viewport.lockTabbing([el,toggle], toggle);
        }
    };

    const close = () => {
        if (active) {
            active = false;
            killAllTweens();
            document.removeEventListener('keyup', keyHandler);
            gsap.to(items, { duration: 0.1, opacity: 0, ease: 'sine.out' });
            gsap.to(shader, { duration: 0.25, autoAlpha: 0, ease: 'power2.inOut' });
            gsap.to(el, { duration: 0.25, x: '100%', ease: 'power2.inOut', onComplete: () => {
                setInitialItemState();
                Viewport.releaseTabbing(toggle);
            }});
        }
    };

    const init = () => {
        setInitialItemState();
        $(shader).on('click', () => {
            Dispatch.emit(Events.MENU_CLOSE);
        });
        Dispatch.on(Events.MENU_OPEN, open);
        Dispatch.on(Events.MENU_CLOSE, close);
    };

    const destroy = () => {
        Dispatch.off(Events.MENU_OPEN, open);
        Dispatch.off(Events.MENU_CLOSE, close);
    };

    return {
        init,
        destroy
    };
};
