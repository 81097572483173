import gsap from 'gsap';
import $ from '@vaersaagod/tools/Dom';
import Dispatch from '@vaersaagod/tools/Dispatch';
import * as Events from '../lib/events';

export default el => {
    const button = $(el);
    const textOpen = button.find('[data-open]');
    const textClose = button.find('[data-close]');
    const hamburgerTop = button.find('line:nth-of-type(1)').get(0);
    const hamburgerMiddle = button.find('line:nth-of-type(2)').get(0);
    const hamburgerBottom = button.find('line:nth-of-type(3)').get(0);

    let active = false;

    const hamburgerToX = () => {
        const offset = 5;
        gsap.to(hamburgerTop, { duration: 0.15, y: offset, ease: 'sine.in' });
        gsap.to(hamburgerBottom, {
            duration: 0.15,
            y: offset * -1,
            ease: 'sine.in',
            onComplete: () => {
                gsap.set(hamburgerMiddle, { opacity: 0 });
                gsap.to(hamburgerTop, { duration: 0.2, rotationZ: '45deg', ease: 'power3.out' });
                gsap.to(hamburgerBottom, { duration: 0.2, rotationZ: '-45deg', ease: 'power3.out' });
            }
        });
    };

    const xToHamburger = () => {
        gsap.to(hamburgerTop, { duration: 0.1, rotationZ: '0deg', ease: 'sine.in' });
        gsap.to(hamburgerBottom, {
            duration: 0.1,
            rotationZ: '0deg',
            ease: 'sine.in',
            onComplete: () => {
                gsap.set(hamburgerMiddle, { opacity: 1 });
                gsap.to(hamburgerTop, { duration: 0.15, y: 0, ease: 'power3.out' });
                gsap.to(hamburgerBottom, { duration: 0.15, y: 0, ease: 'power3.out' });
            }
        });
    };

    const open = () => {
        if (!active) {
            active = true;
            button.attr('aria-expanded', true).attr('aria-label', textClose.text());
            gsap.set(textClose.get(0), { opacity: 0, y: 15 });
            gsap.to(textOpen.get(0), { duration: 0.3, y: -15, opacity: 0, ease: 'power2.out' });
            gsap.to(textClose.get(0), { delay: 0.1, duration: 0.3, y: 0, opacity: 1, ease: 'power2.out' });
            hamburgerToX();
        }
    };

    const close = () => {
        if (active) {
            active = false;
            button.attr('aria-expanded', false).attr('aria-label', textOpen.text());
            gsap.set(textOpen.get(0), { opacity: 0, y: -15 });
            gsap.to(textClose.get(0), { duration: 0.3, y: 15, opacity: 0, ease: 'power2.out' });
            gsap.to(textOpen.get(0), { delay: 0.1, duration: 0.3, y: 0, opacity: 1, ease: 'power2.out' });
            xToHamburger();
        }
    };

    const toggle = e => {
        e.preventDefault();
        Dispatch.emit(button.attr('aria-expanded') === 'false' ? Events.MENU_OPEN : Events.MENU_CLOSE);
    };

    const init = () => {
        gsap.set([hamburgerTop, hamburgerMiddle, hamburgerBottom], { transformOrigin: '50% 50%' });
        button.on('click', toggle);
        Dispatch.on(Events.MENU_OPEN, open);
        Dispatch.on(Events.MENU_CLOSE, close);
    };

    const destroy = () => {
        button.off('click', toggle);
        Dispatch.off(Events.MENU_OPEN, open);
        Dispatch.off(Events.MENU_CLOSE, close);
    };

    return {
        init,
        destroy
    };
};
