import gsap from 'gsap';
import $ from '@vaersaagod/tools/Dom';
import Dispatch from '@vaersaagod/tools/Dispatch';
import Viewport from '@vaersaagod/tools/Viewport';
import * as Events from '../lib/events';

export default el => {
    const dom = $(el);
    const breadcrumbs = dom.find('[aria-label="Breadcrumbs"]').get(0);
    const darks = $('[data-light]').nodes;
    const intersecting = new Set();

    let menuOpen = false;
    let observer = false;

    const onMenuOpen = () => {
        if (!menuOpen) {
            menuOpen = true;
            gsap.to(breadcrumbs, { duration: 0.35, autoAlpha: 0, ease: 'power2.inOut' });
        }
    };

    const onMenuClose = () => {
        if (menuOpen) {
            menuOpen = false;
            gsap.to(breadcrumbs, { duration: 0.25, autoAlpha: 1, ease: 'power2.inOut' });
        }
    };

    const destroyObserver = () => {
        if (observer) {
            darks.forEach(dark => {
                observer.unobserve(dark);
            });
        }
    };

    const getBottomOffset = () => (window.innerHeight - 61) * -1;

    const initObserver = () => {
        destroyObserver();
        intersecting.clear();
        const rect = `-37px 0px ${getBottomOffset()}px 0px`;
        observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    intersecting.add(entry.target);
                } else {
                    intersecting.delete(entry.target);
                }
            });
            dom.toggleClass('scroll-dark-header', intersecting.size === 0);
        }, {
            rootMargin: rect
        });
        darks.forEach(dark => {
            observer.observe(dark);
        });
    };

    const init = () => {
        Dispatch.on(Events.MENU_OPEN, onMenuOpen);
        Dispatch.on(Events.MENU_CLOSE, onMenuClose);
        Viewport.on('resize', initObserver);
        initObserver();
    };

    const destroy = () => {
        Dispatch.off(Events.MENU_OPEN, onMenuOpen);
        Dispatch.off(Events.MENU_CLOSE, onMenuClose);
        Viewport.off('resize', initObserver);
        destroyObserver();
    };

    return {
        init,
        destroy
    };
};
